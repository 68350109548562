import { css } from "@emotion/core";
import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";

const orderedListStyles = css`
  counter-reset: list;
  li {
    list-style: none;
    position: relative;
  }
  li:before {
    counter-increment: list;
    content: "(" counter(list) ")";
    position: absolute;
    left: -1.6em;
  }
`;

const PrivacyPage = (props) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout copyright={false} location={props.location} title={siteTitle}>
      <SEO title="Privacy Policy" />
      <h1
        style={{
          marginTop: rhythm(1),
          marginBottom: rhythm(1),
        }}
      >
        Newsletter Privacy Policy
      </h1>
      <p>
        Email newsletters are issued by Cartant Pty Ltd (Australian Business
        Number 87&nbsp;162&nbsp;771&nbsp;708). This Newsletter Privacy Policy
        pertains to the personally identifying information you voluntarily
        submit in the form of your email address to receive our email
        newsletters.
      </p>
      <p>
        This Newsletter Privacy Policy may change from time to time and was last
        revised 13 May, 2020.
      </p>
      <p>By clicking ‘subscribe’ you agree to the following:</p>
      <ul>
        <li>
          We will use the email address you provide to send you weekly
          newsletter emails.
        </li>
        <li>
          The email address/es you provide will be transferred to our external
          marketing automation service ‘ConvertKit’ for processing in accordance
          with their Privacy Policy and Terms. We use ConvertKit to issue our
          newsletters. We have no control over, and assume no responsibility
          for, the conduct, practices or privacy policies of ConvertKit.
        </li>
      </ul>
      <h2>Unsubscribing</h2>
      <p>
        You can change your mind at any time by clicking the ‘unsubscribe’ link
        in the footer of emails you receive from us.
      </p>
      <p>
        If you want to review and correct the personal information we have about
        you, you can click on ‘update your profile’ in the footer of emails you
        receive from us.
      </p>
      <h2>Security of Your Personal Information</h2>
      <p>
        We are committed to ensuring that your information is secure. We have
        taken reasonable measures to protect information about you from loss,
        theft, misuse or unauthorised access, disclosure, alteration and
        destruction. No physical or electronic security system is impenetrable
        however and you should take your own precautions to protect the security
        of any personally identifiable information you transmit. We cannot
        guarantee that the personal information you supply will not be
        intercepted while transmitted to us or our marketing automation service
        ConvertKit.
      </p>
      <h2>Sharing Your Personal Information</h2>
      <p>We will not disclose your personal information except:</p>
      <ol css={orderedListStyles}>
        <li>as described by this Privacy Policy</li>
        <li>
          after obtaining your permission to a specific use or disclosure or
        </li>
        <li>
          if we are required to do so by a valid legal process or government
          request (such as a court order, a search warrant, a subpoena, a civil
          discovery request, or a statutory requirement). We will retain your
          information for as long as needed in light of the purposes for which
          is was obtained or to comply with our legal obligations and enforce
          our agreements.
        </li>
      </ol>
    </Layout>
  );
};

export default PrivacyPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
